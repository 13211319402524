import { usePathData, useAppContext } from "@/lib/contexts/appContext"
import { useEffect } from "react"

export default function Main({ className, children }) {
  const { mainElem } = useAppContext()
  const { template, path } = usePathData()

  // For some reason the element are not yet present in the DOM after in-app-routing
  // when the browser tries to scroll to the element. The following is used as a last
  // resort when all other attempts failed.
  let count = 0
  const waitForElem = (hash) => {
    count++
    let timer = 0
    const elem = document.getElementById(hash.replace("#", ""))
    if (count > 10) {
      clearTimeout(timer)
      return null
    }
    if (!elem) {
      timer = setTimeout(() => {
        waitForElem(hash)
      }, 10)
    } else {
      clearTimeout(timer)
      //elem.scrollIntoView()
      window.scrollTo({
        top: elem.offsetTop,
        behavior: "smooth"
      })
    }
  }

  useEffect(() => {
    if (window.location.hash) {
      waitForElem(window.location.hash)
      return
    }

    if (mainElem.current) {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" })
      }, 10)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path])

  return (
    <main ref={mainElem} className={className} data-template={template}>
      {children}
    </main>
  )
}
